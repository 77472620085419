import {
  Box,
  InputBase as MuiInput,
  InputBaseComponentProps,
  InputLabel
} from "@mui/material";
import React, { ChangeEvent } from "react";

export interface Props {
  id?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  type?: string;
  multiline?: boolean;
  rows?: number;
  ref?: React.Ref<HTMLInputElement>;
  inputProps: InputBaseComponentProps;
  inputComponent: React.ElementType<InputBaseComponentProps>;
  /* eslint-disable no-unused-vars */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

function Input({
  id = "",
  value = "",
  placeholder = "",
  disabled = false,
  label = "",
  type = "text",
  multiline = false,
  rows = 1,
  ref = null,
  inputComponent,
  inputProps,
  onChange = () => {
    /* parent component should handle this */
  }
}: Props) {
  const style =
    "!py-[6px] px-3 bg-default-white border border-solid !border-gray-4 rounded-md font-sans text-sm font-medium text-gray-10 outline outline-0 " +
    "hover:border hover:!border-gray-5 " +
    "focus:outline-0 focus:!border focus:!border-primary-4 " +
    "invalid:border-red-8 invalid:focus:border invalid:focus:border-red-7 " +
    "disabled:bg-gray-1 disabled:border-gray-4 disabled:cursor-not-allowed disabled:text-gray-5 " +
    "aria-expanded:!border aria-expanded:!border-primary-4";

  return (
    <Box className="w-full">
      {label && (
        <InputLabel
          htmlFor={id}
          classes={{
            root: "block font-sans text-sm font-medium text-gray-10"
          }}
          className="mb-1"
        >
          {label}
        </InputLabel>
      )}
      <Box>
        <MuiInput
          id={id}
          value={value}
          placeholder={placeholder}
          type={type}
          multiline={multiline}
          rows={rows}
          disabled={disabled}
          onChange={onChange}
          inputRef={ref}
          inputComponent={inputComponent}
          inputProps={inputProps}
          slotProps={{
            root: { className: "w-full" },
            input: { className: style }
          }}
        />
      </Box>
    </Box>
  );
}

export default Input;
