import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { sec } from "../../security";
import { addNotification } from "../notifications/notificationsSlice";

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

export const apiFiltersSlice = createApi({
  reducerPath: "apiFilters",
  baseQuery: fetchBaseQuery({
    baseUrl: apiServerUrl,
    prepareHeaders: async (headers) => {
      const token = await sec.getAccessTokenSilently()();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    }
  }),

  tagTypes: [],

  endpoints: (builder) => ({
    getFiltersData: builder.query({
      query: () => `/attorney_filters`
    }),
    getSuggestions: builder.query({
      query: ({ url, inputValue }) => `${url}?query=${inputValue}`
    }),
    getRetentionFilters: builder.query({
      query: ({ type }) => `/settings/filters?type=${type}`
    }),
    getSavedSearches: builder.query({
      query: () => `/api/v1/saved_searches`
    }),
    postSavedSearch: builder.mutation({
      query: ({ data }) => ({
        url: "/api/v1/saved_searches",
        method: "POST",
        body: data
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Saved search created successfully",
              type: "success"
            })
          );
        } catch (error) {
          dispatch(
            addNotification({
              message: "Failed to create saved search",
              type: "danger"
            })
          );
        }
      }
    }),
    deleteSavedSearch: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/saved_searches/${id}`,
        method: "DELETE"
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Saved search deleted successfully",
              type: "success"
            })
          );
        } catch (error) {
          dispatch(
            addNotification({
              message: "Failed to delete saved search",
              type: "danger"
            })
          );
        }
      }
    }),
    patchSavedSearch: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/saved_searches/${id}`,
        method: "PATCH",
        body: data
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Saved search updated successfully",
              type: "success"
            })
          );
        } catch (error) {
          dispatch(
            addNotification({
              message: "Failed to update saved search",
              type: "danger"
            })
          );
        }
      }
    }),
    getSavedSearch: builder.query({
      query: ({ id }) => `/api/v1/saved_searches/${id}`
    })
  })
});

// Export hooks for usage in functional components
export const {
  useGetFiltersDataQuery,
  useGetSuggestionsQuery,
  useGetRetentionFiltersQuery,
  useGetSavedSearchesQuery,
  usePostSavedSearchMutation,
  useDeleteSavedSearchMutation,
  usePatchSavedSearchMutation,
  useGetSavedSearchQuery
} = apiFiltersSlice;
