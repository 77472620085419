import {
  faTriangleExclamation /* triangleExclamation */
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert as MuiAlert } from "@mui/material";
import COLORS from "common/scss/COLORS";
import React, { ReactNode, useEffect, useState } from "react";
import { IconCheck, IconCloseCircle, IconInfoCircle } from "../Icons/Icons";

export interface Props {
  readonly message: string | ReactNode;
  readonly variant: "success" | "warning" | "danger" | "info";
}

interface AlertProps {
  name: string;
  style: string;
  icon: ReactNode;
}

const alertProps: Array<AlertProps> = [
  {
    name: "success",
    style:
      "font-sans font-medium text-sm border rounded-md text-default-success-9 bg-default-success-3 border-default-success-7 stroke-default-success-9",
    icon: <IconCheck width={20} height={20} />
  },
  {
    name: "warning",
    style:
      "font-sans font-medium text-sm border rounded-md text-default-warning-8 bg-default-warning-2 border-default-warning-6 stroke-default-warning-9",
    icon: (
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        style={{
          color: COLORS.default.warning[8],
          fontSize: "1.4rem"
        }}
      />
    )
  },
  {
    name: "danger",
    style:
      "font-sans font-medium text-sm border rounded-md text-default-error-9 bg-default-error-3 border-default-error-7 stroke-default-error-9",
    icon: <IconCloseCircle width={20} height={20} />
  },
  {
    name: "info",
    style:
      "font-sans font-medium text-sm border rounded-md text-default-info-9 bg-default-info-3 border-default-info-7 stroke-default-info-9",
    icon: <IconInfoCircle width={20} height={20} />
  }
];

function Alert(props: Props) {
  const { message, variant } = props;

  const [style, setStyle] = useState<string>("");
  const attributes: AlertProps | undefined = alertProps.find((classes) => {
    return classes.name === variant;
  });

  const handleStyleChange = () => {
    const cssStyle = attributes?.style;

    if (cssStyle) {
      setStyle(cssStyle);
    }
  };

  useEffect(() => {
    handleStyleChange();
  }, [variant]);

  return (
    <MuiAlert className={style} icon={attributes?.icon}>
      {message}
    </MuiAlert>
  );
}

export default Alert;
