import { FormControl, FormHelperText, Typography } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import React from "react";
import Input from "../../FormComponents/Input";

interface Props {
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  inputProps?: any;
  helperText?: string;
}

function FormikInput({
  name,
  type = "text",
  label = "",
  placeholder = "",
  required = false,
  multiline = false,
  rows = 4,
  disabled = false,
  inputProps = {},
  helperText = ""
}: Props) {
  return (
    <FormControl fullWidth>
      {label && (
        <label
          htmlFor={name}
          className="mb-1 block font-sans text-sm font-medium text-gray-10"
        >
          {label}
          {required && <span className="text-sm text-default-error-9">*</span>}
        </label>
      )}
      <Field
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        required={required}
        multiline={multiline}
        rows={rows}
        as={Input}
        disabled={disabled}
        inputProps={inputProps}
        helperText={helperText}
      />
      <ErrorMessage
        component={FormHelperText}
        name={name}
        render={(msg) => (
          <Typography
            component="span"
            className="px-2 py-1 font-sans text-xs font-medium text-default-error-9"
          >
            {msg}
          </Typography>
        )}
      />
    </FormControl>
  );
}

export default FormikInput;
