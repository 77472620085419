import { Box, Button as MuiButton, CircularProgress } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";

export interface Props {
  id: string;
  message: string | ReactElement;
  variant?: "primary" | "success" | "secondary" | "ghost" | "destructive";
  size?: "xs" | "sm" | "md";
  disabled?: boolean;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  isLoading?: boolean;
  icon?: ReactElement | null;
}
interface ButtonClass {
  name: string;
  style: string;
  boxStyle: string;
}

const buttonProps: Array<ButtonClass> = [
  {
    name: "primary",
    boxStyle:
      "w-fit rounded-md border border-default-transparent flex items-center",
    style:
      "whitespace-nowrap h-full normal-case font-sans text-sm font-semibold text-default-white bg-primary-8 shadow-none stroke-default-white " +
      "hover:shadow-none hover:bg-primary-9 " +
      "focus:bg-primary-8 focus:shadow-[0_0_0_1px] focus:shadow-default-white focus:shadow-[0_0_0_1px] focus:shadow-primary-shadow " +
      "active:bg-primary-9 active:shadow-none " +
      "disabled:text-default-white disabled:cursor-not-allowed disabled:shadow-none disabled:bg-primary-4"
  },
  {
    name: "success",
    boxStyle:
      "w-fit rounded-md border border-default-transparent flex items-center",
    style:
      "whitespace-nowrap h-full normal-case font-sans text-sm font-semibold text-default-white bg-default-success-9 shadow-none " +
      "hover:shadow-none hover:bg-default-success-10 " +
      "focus:bg-default-success-9 focus:shadow-[0_0_0_1px] focus:shadow-default-white focus:shadow-[0_0_0_4px] focus:shadow-default-success-4 " +
      "active:bg-default-success-11 active:shadow-none " +
      "disabled:text-default-white disabled:cursor-not-allowed disabled:shadow-none disabled:bg-default-success-7"
  },
  {
    name: "secondary",
    boxStyle: "w-fit rounded-md border border-gray-4 flex items-center",
    style:
      "whitespace-nowrap h-full normal-case font-sans text-sm font-semibold bg-default-white text-default-black shadow-none stroke-gray-10 " +
      "hover:shadow-none hover:bg-gray-0 " +
      "focus:bg-default-white focus:shadow-[0_0_0_1px] focus:shadow-default-white focus:shadow-[0_0_0_4px] focus:shadow-primary-shadow active:bg-gray-0 " +
      "active:shadow-none " +
      "disabled:cursor-not-allowed disabled:shadow-none disabled:bg-default-white"
  },
  {
    name: "ghost",
    boxStyle:
      "w-fit rounded-md border border-default-transparent flex items-center",
    style:
      "whitespace-nowrap h-full normal-case font-sans text-sm font-semibold bg-default-white text-default-black shadow-none stroke-gray-10 " +
      "hover:shadow-none hover:text-primary-8 hover:bg-primary-2 hover:stroke-primary-8 " +
      "focus:bg-default-white focus:text-primary-8 focus:shadow-[0_0_0_1px] focus:shadow-default-white focus:shadow-[0_0_0_4px] focus:shadow-primary-shadow " +
      "active:text-primary-8 active:bg-gray-0 active:shadow-none " +
      "disabled:cursor-not-allowed disabled:shadow-none disabled:text-primary-8 disabled:bg-default-white disabled:opacity-50"
  },
  {
    name: "destructive",
    boxStyle:
      "w-fit rounded-md border border-default-transparent flex items-center",
    style:
      "whitespace-nowrap h-full normal-case font-sans text-sm font-semibold text-default-error-9 bg-default-white shadow-none " +
      "hover:shadow-none hover:bg-default-error-2 " +
      "focus:bg-default-white focus:shadow-[0_0_0_1px] focus:shadow-default-white focus:shadow-[0_0_0_4px] focus:shadow-default-error-7 " +
      "active:bg-default-error-2 active:shadow-none " +
      "disabled:cursor-not-allowed disabled:shadow-none disabled:text-default-error-9 disabled:bg-default-white disabled:opacity-50"
  }
];

function Button({
  id,
  message,
  variant = "primary",
  size = "sm",
  disabled = false,
  onClick = () => {
    /* parent should handle this */
  },
  type = "button",
  isLoading = false,
  icon = null
}: Props) {
  const [style, setStyle] = useState<string>("");
  const [boxStyle, setBoxStyle] = useState<string>("");
  const handleStyleChange = () => {
    let cssStyle = buttonProps.find((classes) => {
      return classes.name === variant;
    })?.style;
    const cssBoxStyle = buttonProps.find((classes) => {
      return classes.name === variant;
    })?.boxStyle;

    if (size === "xs") cssStyle = `${cssStyle} px-2 py-1`;
    if (size === "sm") cssStyle = `${cssStyle} px-3 py-1.5`;
    if (size === "md") cssStyle = `${cssStyle} px-3 py-2.5`;

    setStyle(cssStyle ?? "");
    setBoxStyle(cssBoxStyle ?? "");
  };
  useEffect(() => {
    handleStyleChange();
  }, []);

  useEffect(() => {
    handleStyleChange();
  }, [variant, size]);

  return (
    <Box className={boxStyle} onClick={!disabled ? onClick : undefined}>
      <MuiButton
        className={style}
        disabled={disabled || isLoading}
        type={type}
        id={id}
      >
        {icon && <span className="mr-2">{icon}</span>}
        {message}
        {isLoading && (
          <CircularProgress
            sx={{
              marginLeft: "5px"
            }}
            size={15}
            color="warning"
          />
        )}
      </MuiButton>
    </Box>
  );
}

export default Button;
