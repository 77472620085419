import { Box, Snackbar as MuiSnackBar } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { clearNotification } from "services/notifications/notificationsSlice";
import { useAppDispatch, useAppSelector } from "stateManagement/hooks";
import {
  IconAlertCircle,
  IconCheck,
  IconCloseCircle,
  IconInfoCircle
} from "../Icons/Icons";

interface NotificationsProps {
  name: string;
  style: string;
  icon: ReactNode;
}
const notificationsProps: Array<NotificationsProps> = [
  {
    name: "success",
    style:
      "font-sans font-medium text-sm border rounded-md text-default-success-9 bg-default-success-3 border-default-success-7 fixed w-2/5 stroke-default-success-9",
    icon: <IconCheck width={20} />
  },
  {
    name: "warning",
    style:
      "font-sans font-medium text-sm border rounded-md text-default-warning-8 bg-default-warning-2 border-default-warning-6 fixed w-2/5 stroke-default-warning-9",
    icon: <IconAlertCircle width={20} />
  },
  {
    name: "danger",
    style:
      "font-sans font-medium text-sm border rounded-md text-default-error-9 bg-default-error-3 border-default-error-7 fixed w-2/5 stroke-default-error-9",
    icon: <IconCloseCircle width={20} />
  },
  {
    name: "info",
    style:
      "font-sans font-medium text-sm border rounded-md text-default-info-9 bg-default-info-3 border-default-info-7 fixed w-2/5 stroke-default-info-9",
    icon: <IconInfoCircle width={20} />
  }
];

function Notification() {
  const { open, message, type, placement } = useAppSelector(
    (state) => state.notifications
  );
  const dispatch = useAppDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  useEffect(() => {
    setOpenSnackbar(open);
  }, [open]);

  const handleClose = () => {
    dispatch(clearNotification());
  };

  const [style, setStyle] = useState<string>("");
  const attributes: NotificationsProps | undefined = notificationsProps.find(
    (classes) => {
      return classes.name === type;
    }
  );

  const handleStyleChange = () => {
    let cssStyle = attributes?.style;

    if (placement === "topRight")
      cssStyle = `${attributes?.style} top-5 right-5`;
    if (placement === "bottomRight")
      cssStyle = `${attributes?.style} bottom-5 right-5`;

    setStyle(cssStyle ?? "");
  };

  useEffect(() => {
    handleStyleChange();
  }, []);

  useEffect(() => {
    handleStyleChange();
  }, [type, placement]);

  return (
    <MuiSnackBar
      open={openSnackbar}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Box className={style}>
        <Box className="flex items-center justify-between px-4 py-3">
          <Box className="flex items-center">
            <Box className="flex-shrink-0">{attributes?.icon}</Box>
            <Box className="ml-3">
              <Box className="text-sm font-medium">{message}</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </MuiSnackBar>
  );
}

export default Notification;
