import React from "react";

interface Props {
  width: number;
  height?: number;
  fill?: string;
  stroke?: string;
}

/* LinkedIn Icon */
export function IconLinkedIn({
  width,
  height = 24,
  fill = "transparent"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7778 22H4.22222C2.99492 22 2 21.0051 2 19.7778V4.22222C2 2.99492 2.99492 2 4.22222 2H19.7778C21.0051 2 22 2.99492 22 4.22222V19.7778C22 21.0051 21.0051 22 19.7778 22ZM16.2545 19.2223H19.2224V13.1254C19.2224 10.5457 17.7601 9.29845 15.7175 9.29845C13.674 9.29845 12.8141 10.8898 12.8141 10.8898V9.59265H9.95385V19.2223H12.8141V14.1673C12.8141 12.8129 13.4375 12.0069 14.6309 12.0069C15.7279 12.0069 16.2545 12.7814 16.2545 14.1673V19.2223ZM4.77792 6.55478C4.77792 7.53607 5.56738 8.33173 6.54163 8.33173C7.51588 8.33173 8.30487 7.53607 8.30487 6.55478C8.30487 5.57349 7.51588 4.77783 6.54163 4.77783C5.56738 4.77783 4.77792 5.57349 4.77792 6.55478ZM8.0472 19.2223H5.06474V9.59265H8.0472V19.2223Z"
      />
    </svg>
  );
}

/* Document-Paper Icon */
export function IconDocumentPaper({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M9 7H15M9 11H15M9 15H11M5 5V5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V5V18V19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19V18V5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Download Icon */
export function IconDownload({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M12 16L8 12M12 16L16 12M12 16L12 4M20 15L20 18C20 19.1046 19.1046 20 18 20L6 20C4.89543 20 4 19.1046 4 18L4 15"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Map Marker Pin Icon */
export function IconMapMarkerPin({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M9 4L3 8V20L9 16M9 4L15 8M9 4V16M15 8L21 4V9M15 8V9M9 16L11 17"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 16C22 18.6111 18 22 18 22C18 22 14 18.6111 14 16C14 13.3889 15.7909 12 18 12C20.2091 12 22 13.3889 22 16Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18 16.001L18 16"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Email Icon */
export function IconEmail({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M7 9L10.7506 12.0005C11.481 12.5848 12.519 12.5848 13.2494 12.0005L17 9M21 17L21 7C21 5.89543 20.1046 5 19 5L5 5C3.89543 5 3 5.89543 3 7L3 17C3 18.1046 3.89543 19 5 19L19 19C20.1046 19 21 18.1046 21 17Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Iphone X Bottom Bar Icon */
export function IconIphoneXBottomBar({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M9 18H15"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 3H8C6.89543 3 6 3.89543 6 5L6 19C6 20.1046 6.89543 21 8 21H16C17.1046 21 18 20.1046 18 19V5C18 3.89543 17.1046 3 16 3H14M10 3L14 3M10 3V4M14 3V4M14 4V4C14 4.55228 13.5523 5 13 5L11 5C10.4477 5 10 4.55228 10 4V4M14 4L10 4"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* File Icon */
export function IconFile({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M20 9V19C20 20.1046 19.1046 21 18 21L6 21C4.89543 21 4 20.1046 4 19L4 5C4 3.89543 4.89543 3 6 3H14M20 9V8.82843C20 8.29799 19.7893 7.78929 19.4142 7.41421L15.5858 3.58579C15.2107 3.21071 14.702 3 14.1716 3H14M20 9H16C14.8954 9 14 8.10457 14 7L14 3"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* File Copy Icon */
export function IconFileCopy({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M20 9V15C20 16.1046 19.1046 17 18 17L16 17M20 9V8.82843C20 8.29799 19.7893 7.78929 19.4142 7.41421L15.5858 3.58579C15.2107 3.21071 14.702 3 14.1716 3H14M20 9H16C14.8954 9 14 8.10457 14 7L14 3M14 3H10C8.89543 3 8 3.89543 8 5L8 7M16 17H10C8.89543 17 8 16.1046 8 15L8 7M16 17V19C16 20.1046 15.1046 21 14 21L6 21C4.89543 21 4 20.1046 4 19L4 9C4 7.89543 4.89543 7 6 7H8"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Suitcase 4 Icon */
export function IconSuitcase4({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M15 8V6C15 4.89543 14.1046 4 13 4H11C9.89543 4 9 4.89543 9 6V8M15 8H19C20.1046 8 21 8.89543 21 10V18C21 19.1046 20.1046 20 19 20H5C3.89543 20 3 19.1046 3 18V10C3 8.89543 3.89543 8 5 8H9M15 8H9M7 12V16M17 12V16"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* NoteBlock Text Icon */
export function IconNoteBlockText({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M8 3V7M12 7V3M16 3V7M8 11H16M8 15H12M6 21H18C19.1046 21 20 20.1046 20 19V7C20 5.89543 19.1046 5 18 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Academic Cap Icon */
export function IconAcademicCap({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M21 9L12 4L3 9L6 10.6667M21 9L18 10.6667M21 9V16M6 10.6667L12 14L18 10.6667M6 10.6667V17L12 20L18 17V10.6667"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Globe Earth 2 Icon */
export function IconGlobeEarth2({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <circle
        cx="12"
        cy="12"
        r="9"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 4V4C16 5.10457 15.1046 6 14 6H13.5C12.6716 6 12 6.67157 12 7.5V8C12 8.55228 11.5523 9 11 9V9C10.4477 9 10 9.44772 10 10V11C10 12.1046 10.8954 13 12 13H14C15.1046 13 16 13.8954 16 15V15C16 16.1046 16.8954 17 18 17H19M11 21V18.5C11 17.6716 10.3153 17 9.48682 17V17C8.67282 17 8 16.3401 8 15.5261V15.5261C8 15.1859 7.88229 14.8561 7.66684 14.5928L3.5 9.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Favorite Star Icon */
export function IconFavoriteStar({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M11.5516 3.90849C11.735 3.53687 12.265 3.53687 12.4484 3.90849L14.8226 8.71919C14.8954 8.86677 15.0362 8.96905 15.1991 8.99271L20.508 9.76415C20.9181 9.82374 21.0818 10.3277 20.7851 10.617L16.9435 14.3616C16.8257 14.4765 16.7719 14.642 16.7997 14.8042L17.7066 20.0916C17.7766 20.5001 17.3479 20.8116 16.9811 20.6187L12.2327 18.1223C12.087 18.0457 11.913 18.0457 11.7673 18.1223L7.01888 20.6187C6.65207 20.8116 6.22335 20.5001 6.29341 20.0916L7.20028 14.8042C7.2281 14.642 7.17433 14.4765 7.05648 14.3616L3.21491 10.617C2.91815 10.3277 3.08191 9.82374 3.49202 9.76415L8.80094 8.99271C8.9638 8.96905 9.10458 8.86677 9.17741 8.71919L11.5516 3.90849Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Building 3 Icon */
export function IconBuilding3({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M2 21H4M22 21H20M4 21V8.29975C4 7.50937 4.46547 6.79313 5.18772 6.47212L10.1877 4.2499C11.5103 3.66209 13 4.63021 13 6.07752V8M4 21H13M13 21V8M13 21H20M13 8L18.7878 10.4805C19.5232 10.7957 20 11.5187 20 12.3188V21"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Building 4 Icon */
export function IconBuilding4({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M2 21H4M22 21H20M4 21V8.29975C4 7.50937 4.46547 6.79313 5.18772 6.47212L10.1877 4.2499C11.5103 3.66209 13 4.63021 13 6.07752V8M4 21H13M13 21V8M13 21H20M13 8L18.7878 10.4805C19.5232 10.7957 20 11.5187 20 12.3188V21M8 9H9M8 13H9M8 17H9M16 13H16.5M16 17H17"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Note Edit Icon */
export function IconNoteEdit({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M21 15L21 5C21 3.89543 20.1046 3 19 3L12 3M21 15V15.1716C21 15.702 20.7893 16.2107 20.4142 16.5858L16.5858 20.4142C16.2107 20.7893 15.702 21 15.1716 21H15M21 15L17 15C15.8954 15 15 15.8954 15 17L15 21M15 21L5 21C3.89543 21 3 20.1046 3 19L3 12"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 7L2.70711 5.70711C2.31658 5.31658 2.31658 4.68342 2.70711 4.29289L4.29289 2.70711C4.68342 2.31658 5.31658 2.31658 5.70711 2.70711L7 4M4 7L8.70711 11.7071C8.89464 11.8946 9.149 12 9.41421 12H11C11.5523 12 12 11.5523 12 11V9.41421C12 9.149 11.8946 8.89464 11.7071 8.70711L7 4M4 7L7 4"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Delete Bin 2 Icon */
export function IconDeleteBin2({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M9 7V7C9 5.34315 10.3431 4 12 4V4C13.6569 4 15 5.34315 15 7V7M9 7H15M9 7H6M15 7H18M20 7H18M4 7H6M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M10 11V16M14 16V11"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Edit Pen 6 Icon */
export function IconEditPen6({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M14 6L16.2929 3.70711C16.6834 3.31658 17.3166 3.31658 17.7071 3.70711L20.2929 6.29289C20.6834 6.68342 20.6834 7.31658 20.2929 7.70711L18 10M14 6L7.29289 12.7071C7.10536 12.8946 7 13.149 7 13.4142V16C7 16.5523 7.44772 17 8 17H10.5858C10.851 17 11.1054 16.8946 11.2929 16.7071L18 10M14 6L18 10M3 21H21"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Check Icon */
export function IconCheck({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M5 13.6L9.66667 18L19 7"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Close Circle Icon */
export function IconCloseCircle({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12L9 9M12 12L15 15M12 12L15 9M12 12L9 15"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Info Circle Icon */
export function IconInfoCircle({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <circle
        cx="12"
        cy="12"
        r="9"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15V12"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9V8.9999"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Alert Circle Icon */
export function IconAlertCircle({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <circle
        cx="12"
        cy="12"
        r="9"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8V13"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16V16.0001"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Chevron Down Icon */
export function IconChevronDown({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M17 10L12 15L7 10"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Arrows Expand Full Icon */
export function IconArrowsExpandFull({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M15 19H19M19 19V15M19 19L15 15M9 5H5M5 5V9M5 5L9 9"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 5H19M19 5V9M19 5L15 9M9 19H5M5 19V15M5 19L9 15"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Search Icon */
export function IconSearch({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M20 20L15.9497 15.9497M15.9497 15.9497C17.2165 14.683 18 12.933 18 11C18 7.13401 14.866 4 11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18C12.933 18 14.683 17.2165 15.9497 15.9497Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Close Icon */
export function IconClose({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M12 12L7 7M12 12L17 17M12 12L17 7M12 12L7 17"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Filter Icon */
export function IconFilter({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M18 4H6C4.89543 4 3.97434 4.90932 4.24685 5.97975C4.83604 8.29411 6.43423 10.2054 8.54545 11.2177C9.36832 11.6123 10 12.3858 10 13.2984V19.382C10 20.1253 10.7823 20.6088 11.4472 20.2764L13.4472 19.2764C13.786 19.107 14 18.7607 14 18.382V13.2984C14 12.3858 14.6317 11.6123 15.4545 11.2177C17.5658 10.2054 19.164 8.29411 19.7531 5.97975C20.0257 4.90932 19.1046 4 18 4Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
}

/* Plus Icon */
export function IconPlus({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M5 12H12M19 12H12M12 12V5M12 12V19"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Chevron Left Icon */
export function IconChevronLeft({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M14 7L9 12L14 17"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Chevron Right Icon */
export function IconChevronRight({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M10 7L15 12L10 17"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Users Group 2 Icon */
export function IconUsersGroup2({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <circle
        cx="12"
        cy="11"
        r="4"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 20C17 17.2386 14.7614 15 12 15C9.23858 15 7 17.2386 7 20"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="19"
        cy="7"
        r="3"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 14C23 11.7909 21.2091 10 19 10C17.9855 10 17.0593 10.3776 16.3542 11"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="5"
        cy="7"
        r="3"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 14C1 11.7909 2.79086 10 5 10C6.01445 10 6.94069 10.3776 7.64582 11"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Money Icon */
export function IconMoney({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M3 10L3 8C3 6.89543 3.89543 6 5 6L7 6M3 10C4.33333 10 7 9.2 7 6M3 10L3 14M21 10V8C21 6.89543 20.1046 6 19 6H17M21 10C19.6667 10 17 9.2 17 6M21 10V14M7 6L17 6M21 14V16C21 17.1046 20.1046 18 19 18H17M21 14C19.6667 14 17 14.8 17 18M17 18H7M3 14L3 16C3 17.1046 3.89543 18 5 18H7M3 14C4.33333 14 7 14.8 7 18"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12"
        cy="12"
        r="2"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Calendar Dot Grid 2 Icon */
export function IconCalendarDotGrid2({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M4 9V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V9M4 9V7C4 5.89543 4.89543 5 6 5H8M4 9H20M20 9V7C20 5.89543 19.1046 5 18 5H16M16 5V3M16 5H8M8 3V5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13H12.01"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 13H8.01"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 13H16.01"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17H12.01"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 17H8.01"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Documents 2 Icon */
export function IconDocuments2({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M8 7H13M8 10H13M8 13H10M4 5V5C4 3.89543 4.89543 3 6 3H15C16.1046 3 17 3.89543 17 5V5V15V16C17 17.1046 16.1046 18 15 18H6C4.89543 18 4 17.1046 4 16V15V5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 18V19C6 20.1046 6.89543 21 8 21H18C19.1046 21 20 20.1046 20 19V19V8V8C20 6.89543 19.1046 6 18 6H17"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Scroll Text Icon */
export function IconScrollText({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M15 20C11.8 20 11 17.3333 11 16H3V18C3 19.1046 3.89543 20 5 20H15ZM15 20C16.6569 20 18 18.6569 18 17V10M18 4H7C5.89543 4 5 4.89543 5 6V16M18 4H19C20.1046 4 21 4.89543 21 6V8C21 9.10457 20.1046 10 19 10H18M18 4V10M14 8H9M11 12H9"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* User Icon */
export function IconUser({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <circle
        cx="12"
        cy="8"
        r="5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 21C20 16.5817 16.4183 13 12 13C7.58172 13 4 16.5817 4 21"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 21C20 16.5817 16.4183 13 12 13C7.58172 13 4 16.5817 4 21"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Text Box Icon */
export function IconTextBox({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M8 8H16M16 12H8M8 16H12M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Maximize Box Icon */
export function IconMaximizeBox({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M8 20H4M4 20V16M4 20L10 14M16 4H20M20 4V8M20 4L14 10M10 6H6V10M14 18H18V14"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Sort Icon */
export function IconSort({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M15 9L12 6L9 9"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 15L12 18L9 15"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* SortAsc Icon */
export function IconSortAsc({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M15 9L12 6L9 9"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 15L12 18L9 15"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* SortDes Icon */
export function IconSortDes({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M15 9L12 6L9 9"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 15L12 18L9 15"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Circle Icon */
export function IconCircle({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <circle
        cx="10"
        cy="10"
        r="9"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* More Menu Vertical Icon */
export function IconMoreMenuVertical({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M12.005 11.995L12.005 12.005"
        stroke={stroke}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.005 6.995L12.005 7.005"
        stroke={stroke}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.005 16.995L12.005 17.005"
        stroke={stroke}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Sort Alphabetic Desc Icon */
export function IconSortAlphabeticDesc({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M14 10V8M18 10V8M18 8V6C18 4.89543 17.1046 4 16 4V4C14.8954 4 14 4.89543 14 6V8M18 8H14"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14H18L14 20H18"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 4V20M7 20L10 17M7 20L4 17"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Sort Alphabetic Asc Icon */
export function IconSortAlphabeticAsc({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M14 20V18M18 20V18M18 18V16C18 14.8954 17.1046 14 16 14V14C14.8954 14 14 14.8954 14 16V18M18 18H14"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 4H18L14 10H18"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 20V4M7 4L10 7M7 4L4 7"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Pin Wheel Icon */
export function IconPinWheel({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M17 7C14.9294 7 13.1528 8.25861 12.3935 10.0525C11.9629 11.0697 12.8954 12 14 12H20C21.1046 12 22.0371 11.0697 21.6065 10.0525C20.8472 8.25861 19.0706 7 17 7Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 17C17 14.9294 15.7414 13.1528 13.9475 12.3935C12.9303 11.9629 12 12.8954 12 14L12 20C12 21.1046 12.9303 22.0371 13.9475 21.6065C15.7414 20.8472 17 19.0706 17 17Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 17C9.07059 17 10.8472 15.7414 11.6065 13.9475C12.0371 12.9303 11.1046 12 10 12L4 12C2.89543 12 1.96289 12.9303 2.39346 13.9475C3.15279 15.7414 4.92941 17 7 17Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7C7 9.07059 8.25861 10.8472 10.0525 11.6065C11.0697 12.0371 12 11.1046 12 10L12 4C12 2.89543 11.0697 1.96289 10.0525 2.39346C8.25861 3.1528 7 4.92941 7 7Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Rocket Icon */
export function IconRocket({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <g clipPath="url(#clip0_2713_7381)">
        <path
          d="M9.46447 7.46436L11.1213 5.80751C11.8715 5.05736 12.8889 4.63593 13.9497 4.63593H19.364V10.0501C19.364 11.111 18.9425 12.1284 18.1924 12.8786L16.5355 14.5354M9.46447 7.46436L3.6167 8.11411C3.28255 8.15124 2.97099 8.30099 2.73325 8.53872V8.53872C2.15503 9.11694 2.15503 10.0544 2.73325 10.6326L4.51472 12.4141M9.46447 7.46436L4.51472 12.4141M4.51472 12.4141V12.4141C3.73367 13.1952 3.73367 14.4615 4.51472 15.2425L6.63604 17.3639M11.5858 19.4852V19.4852C10.8047 20.2662 9.53841 20.2662 8.75736 19.4852L6.63604 17.3639M11.5858 19.4852L13.3673 21.2666C13.9455 21.8449 14.883 21.8449 15.4612 21.2666V21.2666C15.6989 21.0289 15.8487 20.7173 15.8858 20.3832L16.5355 14.5354M11.5858 19.4852L16.5355 14.5354M6.63604 17.3639L8.05025 15.9496"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="15"
          cy="9"
          r="1"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2713_7381">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

/* Sparkle Stars Icon */
export function IconSparkleStars({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M6 13C11.6 13 13 8.33333 13 6C13 11.6 17.6667 13 20 13C14.4 13 13 17.6667 13 20C13 14.4 8.33333 13 6 13Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 6C5.6 6 6 4.66667 6 4C6 5.6 7.33333 6 8 6C6.4 6 6 7.33333 6 8C6 6.4 4.66667 6 4 6Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Flag Icon */
export function IconFlag({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M5 20V14M5 5H19V14H5M5 5V14M5 5V4"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Users Group New Icon */
export function IconUsersGroupNew({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <circle
        cx="7"
        cy="15"
        r="2"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 20C10 18.3431 8.65685 17 7 17C5.34315 17 4 18.3431 4 20"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12"
        cy="10"
        r="2"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 15C15 13.3431 13.6569 12 12 12C10.3431 12 9 13.3431 9 15"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="17"
        cy="15"
        r="2"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20C20 18.3431 18.6569 17 17 17C15.3431 17 14 18.3431 14 20"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3V4M19 9H20M5 9H4M7 5L8 6M16 6L17 5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Menu Expand Right Icon */
export function IconMenuExpandRight({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M4 8H13M4 12H20M20 12L17 9M20 12L17 15M4 16H13"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Question Circle Icon */
export function IconQuestionCircle({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <circle
        cx="12"
        cy="12"
        r="9"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15H12.01"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* File Pdf Icon */
export function IconFilePdf({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M20 9V8.82843C20 8.29799 19.7893 7.78929 19.4142 7.41421L15.5858 3.58579C15.2107 3.21071 14.702 3 14.1716 3H14M20 9H16C14.8954 9 14 8.10457 14 7L14 3M20 9V14V19C20 20.1046 19.1046 21 18 21H4.5M14 3H6C4.89543 3 4 3.89543 4 5L4 8"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 15H3.5C4.32843 15 5 14.3284 5 13.5V13.5C5 12.6716 4.32843 12 3.5 12H2V15ZM2 15V17M16 12H15C14.4477 12 14 12.4477 14 13V15M16 15H14M14 15V17M8 12V17H8.5C9.88071 17 11 15.8807 11 14.5V14.5C11 13.1193 9.88071 12 8.5 12H8Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Arrow Up Icon */
export function IconArrowUp({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M11.9999 5L17.9999 11M11.9999 5L5.99988 11M11.9999 5L11.9999 19"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Arrow Right Icon */
export function IconArrowRight({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M19 12L13 6M19 12L13 18M19 12H5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Head Cog Icon */
export function IconHeadCog({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M15.0022 22.0047V18.0037M15.0022 18.0037H17.0778C18.141 18.0037 19.0029 17.1418 19.0029 16.0786V15.5483C19.0029 15.0572 19.3102 14.6185 19.7717 14.4507V14.4507C20.4278 14.2121 20.7294 13.4551 20.4173 12.8307L19.2141 10.4241C19.0752 10.1464 19.0049 9.83983 18.9971 9.52945C18.8895 5.25822 17.2541 2 11.0015 2C4.52028 2 3 7.27396 3 10.0019C3 11.7181 3.95616 14.6571 6.11683 16.5272C6.63567 16.9763 7.00073 17.6006 7.00073 18.2868V22.0047M15.0022 18.0037H14.002"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 7V6M11 7C10.1703 7 9.41921 7.33685 8.87611 7.88125M11 7C11.8297 7 12.5808 7.33685 13.1239 7.88125M11 13V14M11 13C11.8284 13 12.5784 12.6642 13.1213 12.1213M11 13C10.1716 13 9.42157 12.6642 8.87868 12.1213M14 10H15M14 10C14 10.8284 13.6642 11.5784 13.1213 12.1213M14 10C14 9.17288 13.6653 8.42394 13.1239 7.88125M8 10H7M8 10C8 9.17288 8.33473 8.42394 8.87611 7.88125M8 10C8 10.8284 8.33579 11.5784 8.87868 12.1213M8.87611 7.88125L8 7.00514M13.1213 12.1213L14 13M8.87868 12.1213L8 13M13.1239 7.88125L14 7.00514M11 10H11.01"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* Plus Circle Icon */
export function IconPlusCircle({
  width,
  height = 24,
  fill = "transparent",
  stroke = "inherit"
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 12H12M17 12H12M12 12V7M12 12V17"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function RelocateIcon({ width = "24", height = "24", color = "#000" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 6C22 8.61111 18 12 18 12C18 12 14 8.61111 14 6C14 3.38889 15.7909 2 18 2C20.2091 2 22 3.38889 22 6Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 6V6.0001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 21C18 21 20 19.4 20 17M20 17H18L20 15L22 17H20Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 15H5.60555C5.2107 15 4.82469 14.8831 4.49615 14.6641L2 13V17C2 18.1046 2.89543 19 4 19H6L5.42705 20.1459C5.00113 20.9977 5.62056 22 6.57295 22V22C6.85015 22 7.11988 21.9101 7.34164 21.7438L10.4667 19.4C10.8129 19.1404 11.2339 19 11.6667 19H13C13.6667 19 15 18.6 15 17C15 15.4 13.6667 15 13 15H10.5M8 15L6.22631 13.2263C5.77377 12.7738 6.09428 12 6.73426 12V12C6.90574 12 7.07157 12.0613 7.20176 12.1729L10.5 15M8 15H10.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
