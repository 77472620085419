import { Box } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";

export interface Props {
  /**
   * Size of the card
   */
  size?: "xs" | "sm" | "md";
  /**
   * Content of the card
   */
  children: ReactElement;
  /**
   * Additional class name
   */
  className?: string;
  /**
   * Whether to show border or not
   */
  withBorder?: boolean;
  /**
   * Function to be called when the card is clicked
   */
  onClick?: () => void;
  /**
   * Whether the card is successSelected or not
   */
  successSelected?: boolean;
  /**
   * Whether the card is classicSelected or not
   */
  classicSelected?: boolean;
}

function Card({
  size = "md",
  children,
  className = "",
  withBorder = true,
  onClick = () => {
    /* parent handle this */
  },
  successSelected = false,
  classicSelected = false
}: Props) {
  const [style, setStyle] = useState<string>("");

  const handleStyleChange = () => {
    let cssStyle = "";

    if (size === "xs") cssStyle = `${cssStyle} ${className} p-2 `;
    if (size === "sm") cssStyle = `${cssStyle} ${className} p-4 `;
    if (size === "md") cssStyle = `${cssStyle} ${className} p-8 `;

    if (successSelected)
      cssStyle = `${cssStyle} bg-default-success-4 rounded-lg border border-default-success-7`;

    if (classicSelected)
      cssStyle = `${cssStyle} bg-gray-3 rounded-lg border border-gray-5`;

    if (!successSelected && !classicSelected && withBorder)
      cssStyle = `${cssStyle} bg-default-white rounded-lg border border-gray-3`;

    if (!successSelected && !classicSelected && !withBorder)
      cssStyle = `${cssStyle} bg-default-white rounded-lg`;

    setStyle(cssStyle ?? "");
  };

  useEffect(() => {
    handleStyleChange();
  }, [size, successSelected]);

  return (
    <Box className={style} onClick={onClick}>
      {children}
    </Box>
  );
}

export default Card;
