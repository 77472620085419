import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface Props {
  message: string | number | React.ReactNode;
  variant:
    | "neutral"
    | "info"
    | "success"
    | "negative"
    | "warning"
    | "precaution"
    | "honors";
  type: "Subtle" | "Colorful";
  icon?: React.ReactNode;
  withLoading?: boolean;
  loadingColor?: string;
}

interface BadgeClass {
  name: string;
  boxStyleSubtle: string;
  styleSubtle: string;
  boxStyleColorful: string;
  styleColorful: string;
}

const buttonProps: Array<BadgeClass> = [
  {
    name: "neutral",
    boxStyleSubtle: "rounded-md bg-gray-1 py-1 px-2 w-fit stroke-gray-10",
    styleSubtle: "normal-case font-sans text-sm font-medium text-gray-10",
    boxStyleColorful: "rounded-md bg-gray-9 py-1 px-2 w-fit",
    styleColorful:
      "normal-case font-sans text-sm font-medium text-default-white"
  },
  {
    name: "info",
    boxStyleSubtle:
      "rounded-md bg-default-info-3 py-1 px-2 w-fit stroke-default-info-9",
    styleSubtle:
      "normal-case font-sans text-sm font-medium text-default-info-9",
    boxStyleColorful: "rounded-md bg-default-info-9 py-1 px-2 w-fit",
    styleColorful:
      "normal-case font-sans text-sm font-medium text-default-white"
  },
  {
    name: "success",
    boxStyleSubtle:
      "rounded-md bg-default-success-3 py-1 px-2 w-fit stroke-default-white",
    styleSubtle:
      "whitespace-nowrap normal-case font-sans text-sm font-medium text-default-success-9",
    boxStyleColorful:
      "rounded-md bg-default-success-9 py-1 px-2 w-fit stroke-default-white",
    styleColorful:
      "whitespace-nowrap normal-case font-sans text-sm font-medium text-default-white"
  },
  {
    name: "negative",
    boxStyleSubtle:
      "rounded-md bg-default-error-3 py-1 px-2 w-fit stroke-default-error-9",
    styleSubtle:
      "normal-case font-sans text-sm font-medium text-default-error-9",
    boxStyleColorful: "rounded-md bg-default-error-9 py-1 px-2 w-fit",
    styleColorful:
      "normal-case font-sans text-sm font-medium text-default-white"
  },
  {
    name: "precaution",
    boxStyleSubtle: "rounded-md bg-[#fbd000a8] py-1 px-2 w-fit stroke-gray-7",
    styleSubtle: "normal-case font-sans text-sm font-medium text-default-white",
    boxStyleColorful: "rounded-md bg-[#fbd000a8] py-1 px-2 w-fit stroke-gray-7",
    styleColorful:
      "normal-case font-sans text-sm font-medium text-default-white"
  },
  {
    name: "warning",
    boxStyleSubtle:
      "rounded-md bg-default-warning-2 py-1 px-2 w-fit stroke-default-white",
    styleSubtle:
      "normal-case font-sans text-sm font-medium text-default-warning-9",
    boxStyleColorful:
      "rounded-md bg-default-warning-9 py-1 px-2 w-fit stroke-default-white",
    styleColorful:
      "whitespace-nowrap normal-case font-sans text-sm font-medium text-default-white"
  },
  {
    name: "honors",
    boxStyleSubtle:
      "rounded-md bg-default-success-3 py-1 px-2 w-fit stroke-default-white",
    styleSubtle: "normal-case font-sans text-xs font-medium text-default-white",
    /*  */
    boxStyleColorful:
      "rounded-md bg-default-white py-1 px-2 w-fit stroke-default-white border border-default-success-9",
    styleColorful:
      "normal-case font-sans text-xs font-medium text-default-success-9"
  }
];

function Badge({
  message,
  variant,
  type,
  icon = null,
  withLoading = false,
  loadingColor = "white"
}: Props) {
  const [fontStyle, setFontStyle] = useState<string>("");
  const [boxStyle, setBoxStyle] = useState<string>("");
  const handleStyleChange = () => {
    const style = buttonProps.find((item) => item.name === variant);
    if (style) {
      if (type === "Subtle") {
        setFontStyle(style.styleSubtle);
        setBoxStyle(style.boxStyleSubtle);
      } else {
        setFontStyle(style.styleColorful);
        setBoxStyle(style.boxStyleColorful);
      }
    }
  };

  useEffect(() => {
    handleStyleChange();
  }, []);

  useEffect(() => {
    handleStyleChange();
  }, [variant, type]);

  return (
    <Box className={boxStyle} display="flex" alignItems="center">
      {icon && <Box className="mr-1">{icon}</Box>}
      <Typography className={fontStyle}>{message}</Typography>
      {withLoading && (
        <Box className="ml-2 " sx={{ color: loadingColor }}>
          <CircularProgress size={15} color="inherit" />
        </Box>
      )}
    </Box>
  );
}

export default Badge;
