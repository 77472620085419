/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import { colors } from "common/scss/themes";
import { ErrorMessage } from "formik";
import React, { FocusEvent } from "react";
import Checkbox from "../Checkbox";
import InputBase from "./utils/InputBase";

export interface Props {
  id?: string;
  selectLabel?: string;
  label?: string;
  className?: string;
  options: { value: string | number; label: string | number }[];
  value?: "" | HTMLInputElement;
  onChange?: (event: SelectChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  hidePlaceHolder?: boolean;
  name?: string;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  valueToDisplaySelectOne?: any;
  multiple?: boolean;
  withCheckbox?: boolean;
}

function Select({
  id = "",
  selectLabel = "",
  label = "",
  className = "",
  options,
  value = "",
  hidePlaceHolder = false,
  onChange = () => {
    /*  */
  },
  onBlur = () => {
    /*  */
  },
  disabled = false,
  name = "",
  required = false,
  error = false,
  valueToDisplaySelectOne = "",
  multiple = false,
  withCheckbox = false
}: Props) {
  return (
    <FormControl fullWidth>
      <Box display="flex" alignItems="center">
        {label && (
          <label
            htmlFor={name}
            className="mb-1 block font-sans text-sm font-medium text-gray-10"
          >
            {label}
            {required && (
              <span className="text-sm text-default-error-9">*</span>
            )}
          </label>
        )}
      </Box>

      <MuiSelect
        id={id}
        name={name}
        displayEmpty
        className={className}
        input={<InputBase value={value} />}
        value={value}
        label={selectLabel}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        multiple={multiple}
        MenuProps={{
          classes: {
            paper: "rounded-md mt-1",
            list: "p-px"
          }
        }}
      >
        <MenuItem
          hidden={hidePlaceHolder}
          value={valueToDisplaySelectOne}
          className="p-2 font-sans text-sm font-medium text-gray-10 hover:text-primary-8 hover:bg-primary-1"
          sx={{
            "&.Mui-selected": {
              color: colors.primary[8],
              backgroundColor: "white",
              "&:hover, &.Mui-focusVisible": {
                backgroundColor: colors.primary[1]
              }
            }
          }}
        >
          {selectLabel}
        </MenuItem>

        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className="h-[36px] min-h-[36px] p-2 font-sans text-sm font-medium text-gray-10 hover:text-primary-8 hover:bg-primary-1"
            sx={{
              "&.Mui-selected": {
                color: colors.primary[8],
                backgroundColor: "white",
                "&:hover, &.Mui-focusVisible": {
                  backgroundColor: colors.primary[1]
                }
              }
            }}
          >
            {withCheckbox && <Checkbox label={option.label} />}
            {!withCheckbox && option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && (
        <ErrorMessage
          component={FormHelperText}
          name={name}
          render={(msg) => (
            <Typography
              component="span"
              className="px-2 py-1 font-sans text-xs font-medium text-default-error-9"
            >
              {msg}
            </Typography>
          )}
        />
      )}
    </FormControl>
  );
}

export default Select;
