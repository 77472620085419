import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

interface AsyncState {
  isLoading: boolean;
  isSuccessful: boolean;
  isError: boolean;
}

interface FiltersState extends AsyncState {
  filters?: any;
  saveFiltersModal: boolean;
  viewSavedFiltersModal: boolean;
  tableOptions?: { page: number; pageSize: number };
}

const initialState: FiltersState = {
  isLoading: false,
  isSuccessful: false,
  isError: false,
  filters: {},
  saveFiltersModal: false,
  viewSavedFiltersModal: false,
  tableOptions: {
    page: 1,
    pageSize: 25
  }
};

const getCurrentState = (state: any) => {
  try {
    return JSON.parse(JSON.stringify(state));
  } catch (e) {
    return null;
  }
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.isLoading = false;
      state.isSuccessful = false;
      state.isError = false;
      state.filters = null;
      state.tableOptions = {
        page: 1,
        pageSize: 25
      };
    },
    setFilters: (state, action) => {
      return {
        ...state,
        filters: action.payload,
        tableOptions: {
          page: 1,
          pageSize: 25
        }
      };
    },
    setSaveFiltersModal: (state, action) => {
      return {
        ...state,
        saveFiltersModal: action.payload
      };
    },
    setViewSavedFiltersModal: (state, action) => {
      return {
        ...state,
        viewSavedFiltersModal: action.payload
      };
    },
    removeFilter: (state, action) => {
      const currentState = getCurrentState(state);

      const newFilters = currentState.filters;

      newFilters[action.payload.name] = newFilters[action.payload.name].filter(
        (object: any) => {
          return object.value !== action.payload.data.value;
        }
      );

      return {
        ...state,
        filters: newFilters
      };
    },
    removeFilterCategory: (state, action) => {
      const currentState = getCurrentState(state);
      const newFilters = currentState.filters;
      delete newFilters[action.payload];
      return {
        ...state,
        filters: _.isEmpty(newFilters) ? null : newFilters
      };
    },
    setPage: (state, action) => {
      return {
        ...state,
        tableOptions: {
          page: action.payload,
          pageSize: state.tableOptions?.pageSize || 25
        }
      };
    },
    setPageSize: (state, action) => {
      return {
        ...state,
        tableOptions: {
          page: state.tableOptions?.page || 1,
          pageSize: action.payload
        }
      };
    }
  }
});

export const {
  resetFilters,
  setFilters,
  setSaveFiltersModal,
  setViewSavedFiltersModal,
  removeFilter,
  removeFilterCategory,
  setPage,
  setPageSize
} = filtersSlice.actions;

export default filtersSlice.reducer;
