import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
// eslint-disable-next-line import/no-cycle
import { PageLoader, TopNav } from "common/components";
import { APP_ROUTES } from "common/constants/ROUTES";
import React from "react";

import logoH from "../../../assets/logos/logoH.png";

function SuspenseLoader() {
  const { isAuthenticated } = useAuth0();

  return (
    <Box className="bg-default-background min-h-screen">
      {isAuthenticated && (
        <TopNav
          isLogged={isAuthenticated}
          tabs={[
            {
              id: "nav-firm",
              label: "Firm",
              name: "firm",
              path: APP_ROUTES.FIRM
            },
            {
              id: "nav-jobs",
              label: "Jobs",
              name: "jobs",
              path: APP_ROUTES.JOBS
            },
            {
              id: "nav-attorneys",
              label: "Search Candidates",
              name: "attorneys",
              path: APP_ROUTES.ATTORNEYS
            },
            {
              id: "nav-retention",
              label: "Retention",
              name: "retention",
              path: APP_ROUTES.RETENTION
            }
          ]}
          logo={logoH}
          offlineLogo={logoH}
          avatar=""
          withSearch={false}
          onLogoutClick={() => {
            /*  */
          }}
        />
      )}
      <PageLoader />
    </Box>
  );
}

export default SuspenseLoader;
