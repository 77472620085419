import type { PreloadedState } from "@reduxjs/toolkit";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
// Attorneys
import { apiAttorneysSlice } from "services/attorneys/attorneysApi";
// Employees
import { apiEmployeesSlice } from "services/employees/employeesApi";
// Filters
import { apiFiltersSlice } from "services/filters/filtersApi";
import filtersReducer from "services/filters/filtersSlice";
// Firm
import { apiFirmSlice } from "services/firm/firmApi";
// Impersonate
import { apiImpersonateSlice } from "services/impersonate/impersonateApi";
import impersonateReducer from "services/impersonate/impersonateSlice";
// Job
import { apiJobSlice } from "services/job/jobApi";
import jobReducer from "services/job/jobSlice";
// Jobs
import { apiJobsSlice } from "services/jobs/jobsApi";
// Notifications
import notificationsReducer from "services/notifications/notificationsSlice";
// Permissions
import permissionsReducer from "services/permissions/permissionsSlice";
// Public
import { apiPublicSlice } from "services/public/publicApi";
// User
import userReducer from "services/user/userSlice";
import usersReducer from "services/users/usersSlice";

const rootReducer = combineReducers({
  [apiFiltersSlice.reducerPath]: apiFiltersSlice.reducer,
  [apiFirmSlice.reducerPath]: apiFirmSlice.reducer,
  [apiAttorneysSlice.reducerPath]: apiAttorneysSlice.reducer,
  [apiJobsSlice.reducerPath]: apiJobsSlice.reducer,
  [apiJobSlice.reducerPath]: apiJobSlice.reducer,
  [apiEmployeesSlice.reducerPath]: apiEmployeesSlice.reducer,
  [apiPublicSlice.reducerPath]: apiPublicSlice.reducer,
  [apiImpersonateSlice.reducerPath]: apiImpersonateSlice.reducer,

  users: usersReducer,
  user: userReducer,
  filters: filtersReducer,
  notifications: notificationsReducer,
  job: jobReducer,
  permissions: permissionsReducer,
  impersonate: impersonateReducer
});

const middleware = (getDefaultMiddleware: any) => {
  return [
    // Other middleware that you want to include
    // ...
    ...getDefaultMiddleware({
      serializableCheck: false
    }),
    // Middleware for your API slices
    apiAttorneysSlice.middleware,
    apiFiltersSlice.middleware,
    apiFirmSlice.middleware,
    apiJobsSlice.middleware,
    apiJobSlice.middleware,
    apiEmployeesSlice.middleware,
    apiPublicSlice.middleware,
    apiImpersonateSlice.middleware
  ];
};

export type RootState = ReturnType<typeof rootReducer>;

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware,
    preloadedState
  });

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
