import { Tooltip as MuiTooltip } from "@mui/material";
import React, { ReactElement } from "react";

export interface Props {
  message: string | ReactElement;
  children: ReactElement;
  placement?:
    | "top-start"
    | "top"
    | "top-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-end"
    | "bottom"
    | "bottom-start"
    | "left-end"
    | "left"
    | "left-start";
  dark?: boolean;
  withArrow?: boolean;
}
function Tooltip({
  message,
  children,
  placement = "top",
  dark = false,
  withArrow = true
}: Props) {
  const fontStyle = "normal-case font-sans text-gray-10 text-xs font-medium";
  return (
    <MuiTooltip
      className={fontStyle}
      title={message}
      arrow={withArrow}
      placement={placement}
      componentsProps={{
        tooltip: {
          className:
            dark === true
              ? "bg-gray-10 font-sans text-default-white text-xs font-medium rounded-lg p-2"
              : "bg-gray-4 font-sans text-gray-10 text-xs font-medium rounded-lg p-2 max-w-xl"
        },
        arrow: {
          className: dark === true ? "before:bg-gray-10" : "before:bg-gray-4"
        }
      }}
    >
      {children}
    </MuiTooltip>
  );
}

export default Tooltip;
