import { Checkbox as MuiCheckbox, FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "common/scss/themes";
import React from "react";

export interface Props {
  id?: string;
  label?: string | number;
  labelPlacement?: "start" | "end" | "top" | "bottom";
  indeterminate?: boolean;
  checked?: boolean;
  value?: string | number;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Icon = styled("span")(() => ({
  borderRadius: "4px",
  width: 19,
  height: 19,
  border: `1px solid ${colors.gray[4]}`,
  "input:disabled ~ &": {
    boxShadow: "none",
    border: `1px solid ${colors.gray[5]}`
  },
  "input:focus ~ &": {
    boxShadow: `0px 0px 0px 1px #FFFFFF, 0px 0px 0px 4px ${colors.primary.shadow}`
  }
}));

const CheckedIcon = styled(Icon)({
  backgroundColor: colors.primary[8],
  border: `1px solid ${colors.primary[8]}`,
  "&:before": {
    display: "block",
    width: 17,
    height: 17,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""'
  },
  "input:hover ~ &": {
    backgroundColor: colors.primary[8]
  },
  "input:disabled ~ &": {
    backgroundColor: colors.gray[5]
  }
});
function Checkbox({
  id = "",
  label = "",
  labelPlacement = "end",
  indeterminate = false,
  checked = false,
  value = "",
  disabled = false,
  onChange = () => {
    // do nothing
  }
}: Props) {
  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          id={id}
          icon={<Icon />}
          checkedIcon={<CheckedIcon />}
          disableRipple
          checked={checked}
          indeterminate={indeterminate}
          value={value}
          disabled={disabled}
          onChange={onChange}
          sx={{
            "&.MuiCheckbox-indeterminate": {
              color: colors.primary[8],
              borderRadius: "4px",
              width: 37,
              height: 37,
              "&.Mui-disabled": {
                color: colors.gray[5]
              }
            }
          }}
        />
      }
      labelPlacement={labelPlacement}
      label={label}
      classes={{
        label: "font-sans text-sm font-medium text-gray-10",
        root: "m-0"
      }}
    />
  );
}

export default Checkbox;
