import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";

interface PageLoaderProps {
  padding?: "none" | "sm" | "md" | "lg";
  size?: "xs" | "sm" | "md" | "lg";
}
function PageLoader({ padding = "lg", size = "lg" }: PageLoaderProps) {
  const handlePadding = () => {
    switch (padding) {
      case "none":
        return 0;
      case "sm":
        return 2;
      case "md":
        return 3;
      case "lg":
        return 5;
      default:
        return 5;
    }
  };

  const handleSize = () => {
    switch (size) {
      case "xs":
        return 10;
      case "sm":
        return 20;
      case "md":
        return 30;
      case "lg":
        return 40;
      default:
        return 40;
    }
  };

  return (
    <Box display="flex" justifyContent="center" py={handlePadding()}>
      <CircularProgress color="warning" size={handleSize()} />
    </Box>
  );
}

export default PageLoader;
