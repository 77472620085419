import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userService from "./user.service";

interface AsyncState {
  isLoadingMe?: boolean;
  isSuccessfulMe?: boolean;
  isErrorMe?: boolean;
  errorCode?: number;
}

interface UserState extends AsyncState {
  user?: any;
}

type GetUserData = {
  accessToken: string;
};

const initialState: UserState = {
  isLoadingMe: false,
  isSuccessfulMe: false,
  isErrorMe: false,
  errorCode: 0,
  user: null
};

export const getUserData = createAsyncThunk(
  "users/getUserData",
  async (data: GetUserData, thunkAPI) => {
    try {
      const { accessToken } = data;
      return await userService.getUserData(accessToken);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.pending, (state) => {
        state.isLoadingMe = true;
        state.isSuccessfulMe = false;
        state.isErrorMe = false;
        state.errorCode = 0;
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.isLoadingMe = false;
        state.isSuccessfulMe = true;
        state.isErrorMe = false;
        state.user = action.payload?.user;
        state.errorCode = 0;
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.isLoadingMe = false;
        state.isSuccessfulMe = false;
        state.isErrorMe = true;
        state.errorCode = action.payload?.code;
        state.user = null;
      });
  }
});

export default usersSlice.reducer;
