/* eslint-disable react/require-default-props */
// TODO:fix props
import {
  Box,
  InputAdornment,
  InputLabel,
  TextField as MuiTextField
} from "@mui/material";
import { colors } from "common/scss/themes";
import React, { ChangeEvent } from "react";

export interface Props {
  id?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  type?: string;
  multiline?: boolean;
  rows?: number;
  ref?: React.Ref<HTMLInputElement>;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  /* eslint-disable no-unused-vars */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  helperText?: string;
  inputProps?: object;
}

function Input(props: Props) {
  const {
    id,
    value,
    placeholder,
    disabled,
    required,
    label,
    type,
    multiline,
    rows,
    ref,
    startAdornment,
    endAdornment,
    onChange,
    onKeyPress,
    helperText,
    inputProps
  } = props;

  return (
    <Box className="w-full">
      {label && (
        <InputLabel
          htmlFor={id}
          classes={{
            root: "block font-sans text-sm font-medium text-gray-10"
          }}
          className="mb-1"
        >
          {label}
        </InputLabel>
      )}
      <Box>
        <MuiTextField
          id={id}
          value={value}
          placeholder={placeholder}
          type={type}
          multiline={multiline}
          rows={rows}
          disabled={disabled}
          onChange={onChange}
          inputRef={ref}
          fullWidth
          onKeyUp={onKeyPress}
          required={required}
          InputProps={{
            startAdornment: startAdornment && (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ),
            endAdornment: endAdornment && (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
            ...inputProps
          }}
          helperText={helperText}
          sx={{
            "& .MuiInputBase-input": {
              padding: "7px 12px",
              fontSize: "14px",
              fontFamily: "Noto Sans, sans-serif",
              lineHeight: "1.25rem",
              color: "#1a202c",
              backgroundColor: "#fff"
            },
            "& .MuiInputBase-root": {
              border: `none`
            },
            "& .MuiOutlinedInput-root": {
              /* padding: "5px 0px", */
              "& fieldset": {
                borderColor: colors.gray[4],
                borderRadius: "0.375rem"
              },
              "&:hover fieldset": {
                borderColor: colors.gray[5]
              },
              "&.Mui-focused fieldset": {
                borderWidth: "1px",
                borderColor: colors.primary[4]
              },
              "&.Mui-disabled fieldset": {
                borderColor: colors.gray[8],
                backgroundColor: colors.gray[1],
                opacity: 0.2
              },
              "&.Mui-error fieldset": {
                borderColor: colors.default.error[7]
              },
              "&.Mui-error:hover fieldset": {
                borderColor: colors.default.error[7]
              },
              "&.Mui-error.Mui-focused fieldset": {
                borderColor: colors.default.error[7]
              },
              "&.Mui-error.Mui-disabled fieldset": {
                borderColor: colors.gray[4],
                backgroundColor: colors.gray[1]
              }
            }
          }}
          FormHelperTextProps={{
            classes: {
              root: "font-sans text-xs font-medium text-default-error-9"
            }
          }}
          {...props}
        />
      </Box>
    </Box>
  );
}

Input.defaultProps = {
  id: "",
  value: "",
  placeholder: "",
  disabled: false,
  required: false,
  label: "",
  type: "text",
  multiline: false,
  rows: 1,
  ref: null,
  startAdornment: null,
  endAdornment: null,
  onChange: () => {
    /* parent component should handle this */
  },
  onKeyPress: () => {
    /* parent component should handle this */
  },
  helperText: "",
  inputProps: {}
};

export default Input;
